import emailjs from '@emailjs/browser'

export const passwordResetEmail = async (email, resetKey) => {
  await emailjs.send(
    'freudas-test',
    'forgot_password',
    {
      verification_link: `https://freudas-webapp.herokuapp.com/reset-password?email=${email}&key=${resetKey}`,
      user_email: email,
    },
    "user_fXA3ZSux2HSXzzX9yKcmR",
  )
  return true
}

export const signupEmail = async (email, name, resetKey) => {
  await emailjs.send(
    'freudas-test',
    'verification_email',
    {
      verification_link: `https://freudas-webapp.herokuapp.com/verify-user?email=${email}&key=${resetKey}`,
      user_email: email,
      to_name: name,
    },
    "user_fXA3ZSux2HSXzzX9yKcmR",
  )
  return true
}
