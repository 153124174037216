import React from 'react'
import { Container, Typography } from '@mui/material'

import "./style.css"

export function Cookies() {

    return (
        <Container className='cookieWrapper'>
            <div className="topShape">
                </div>
            < Container >
                <div className="cookieContent">
                    <Typography variant="h3" className="cookieTitle">
                        Privacy policy GDPR
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        A. Introduction
                    </Typography>

                    <Typography component="div">
                        <ol className="cookieList">
                            <li>The privacy of our website visitors is very important to us, and we are committed to safeguarding it. This policy explains what we will do with your personal information.</li>
                            <li>Consenting to our use of cookies in accordance with the terms of this policy when you first visit our website permits us to use cookies every time you visit our website.</li>
                        </ol>
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        B. Collecting personal information
                    </Typography>

                    <Typography className="cookieText">
                        The following types of personal information may be collected, stored, and used:
                    </Typography>

                    <Typography component="div">
                        <ol className="cookieList">
                            <li>information about your computer including your IP address, geographical location, browser type and version, and operating system;</li>
                            <li>information about your visits to and use of this website including the referral source, length of visit, page views, and website navigation paths;</li>
                            <li>information, such as your email address, that you enter when you register with our website;</li>
                            <li>information that you enter when you create a profile on our website-for example, your name, profile pictures, gender, birthday, relationship status, interests and hobbies, educational details, and employment details;</li>
                            <li>information, such as your name and email address, that you enter in order to set up subscriptions to our emails and/or newsletters;</li>
                            <li>information that you enter while using the services on our website;</li>
                            <li>information that is generated while using our website, including when, how often, and under what circumstances you use it;</li>
                            <li>information relating to anything you purchase, services you use, or transactions you make through our website, which includes your name, address, telephone number, email address, and credit card details</li>
                            <li>information that you post to our website with the intention of publishing it on the internet, which includes your username, profile pictures, and the content of your posts;</li>
                            <li>information contained in any communications that you send to us by email or through our website, including its communication content and metadata;</li>
                            <li>any other personal information that you send to us.</li>
                        </ol>
                    </Typography>

                    <Typography className="cookieText">Before you disclose to us the personal information of another person, you must obtain that person's consent to both the disclosure and the processing of that personal information in accordance with this policy
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        C. Using your personal information
                    </Typography>

                    <Typography className="cookieText">
                        Personal information submitted to us through our website will be used for the purposes specified in this policy or on the relevant pages of the website. We may use your personal information for the following:
                    </Typography>

                    <Typography component="div">
                        <ol className="cookieList">
                            <li>administering our website and business;</li>
                            <li>personalizing our website for you;</li>
                            <li>enabling your use of the services available on our website;</li>
                            <li>sending you goods purchased through our website;</li>
                            <li>supplying services purchased through our website;</li>
                            <li>sending statements, invoices, and payment reminders to you, and collecting payments from you;</li>
                            <li>sending you non-marketing commercial communications;</li>
                            <li>sending you email notifications that you have specifically requested;</li>
                            <li>sending you our email newsletter, if you have requested it (you can inform us at any time if you no longer require the newsletter);</li>
                            <li>sending you marketing communications relating to our business or the businesses of carefully-selected third parties which we think may be of interest to you, by post or, where you have specifically agreed to this, by email or similar technology (you can inform us at any time if you no longer require marketing communications);</li>
                            <li>providing third parties with statistical information about our users (but those third parties will not be able to identify any individual user from that information);</li>
                            <li>dealing with inquiries and complaints made by or about you relating to our website;</li>
                            <li>keeping our website secure and prevent fraud;</li>
                            <li>verifying compliance with the terms and conditions governing the use of our website (including monitoring private messages sent through our website private messaging service); and</li>
                            <li>other uses.</li>
                        </ol>
                    </Typography>

                    <Typography className="cookieText">
                        If you submit personal information for publication on our website, we may publish and otherwise use that information in accordance with the license you grant to us.
                    </Typography>

                    <Typography className="cookieText">
                        Your privacy settings can be used to limit the publication of your information on our website and can be adjusted using privacy controls on the website.                </Typography>

                    <Typography className="cookieText">
                        We will not, without your express consent, supply your personal information to any third party for their or any other third party's direct marketing.                </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        D. Disclosing personal information
                    </Typography>

                    <Typography className="cookieText">
                        We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers, or subcontractors as reasonably necessary for the purposes set out in this policy.
                    </Typography>

                    <Typography className="cookieText">
                        We may disclose your personal information to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) as reasonably necessary for the purposes set out in this policy.
                    </Typography>

                    <Typography className="cookieText">
                        We may disclose your personal information:
                    </Typography>

                    <Typography component="div">
                        <ol className="cookieList">
                            <li>to the extent that we are required to do so by law;</li>
                            <li>in connection with any ongoing or prospective legal proceedings;</li>
                            <li>in order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk);</li>
                            <li>to the purchaser (or prospective purchaser) of any business or asset that we are (or are contemplating) selling; and</li>
                            <li>to any person who we reasonably believe may apply to a court or other competent authority for disclosure of that personal information where, in our reasonable opinion, such court or authority would be reasonably likely to order disclosure of that personal information.</li>
                        </ol>
                    </Typography>

                    <Typography className="cookieText">
                        Except as provided in this policy, we will not provide your personal information to third parties.
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        E. International data transfers
                    </Typography>

                    <Typography component="div">
                        <ol className="cookieList">
                            <li>Information that we collect may be stored, processed in, and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this policy.</li>
                            <li>Information that we collect may be transferred to the following countries which do not have data protection laws equivalent to those in force in the European Economic Area: the United States of America, Russia, Japan, China, and India.</li>
                            <li>Personal information that you publish on our website or submit for publication on our website may be available, via the internet, around the world. We cannot prevent the use or misuse of such information by others. </li>
                            <li>You expressly agree to the transfers of personal information described in this Section E.</li>
                        </ol>
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        F. Retaining personal information
                    </Typography>
                    <Typography component="div">
                        <ol className="cookieList">
                            <li>This Section F sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations regarding the retention and deletion of personal information.</li>
                            <li>Personal information that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</li>
                            <li> Notwithstanding the other provisions of this Section F, we will retain documents (including electronic documents) containing personal data:
                                <ul className="cookieListSecondary">
                                    <li>a. to the extent that we are required to do so by law;</li>
                                    <li>b. if we believe that the documents may be relevant to any ongoing or prospective legal proceedings; and</li>
                                    <li>c. in order to establish, exercise, or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk). </li>
                                </ul>
                            </li>
                        </ol>
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        G. Security of your personal information
                    </Typography>
                    <Typography component="div">
                        <ol className="cookieList">
                            <li>We will take reasonable technical and organizational precautions to prevent the loss, misuse, or alteration of your personal information.</li>
                            <li>We will store all the personal information you provide on our secure (password- and firewall-protected) servers.</li>
                            <li>All electronic financial transactions entered into through our website will be protected by encryption technology.</li>
                            <li>You acknowledge that the transmission of information over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</li>
                            <li>You are responsible for keeping the password you use for accessing our website confidential; we will not ask you for your password (except when you log in to our website).</li>
                        </ol>
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        H. Amendments
                    </Typography>
                    <Typography className="cookieText">
                        We may update this policy from time to time by publishing a new version on our website. You should check this page occasionally to ensure you understand any changes to this policy. We may notify you of changes to this policy by email or through the private messaging system on our website.
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        I. Third party websites
                    </Typography>
                    <Typography className="cookieText">
                        Our website includes hyperlinks to, and details of, third party websites. We have no control over, and are not responsible for, the privacy policies and practices of third parties.
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        J. Updating information
                    </Typography>
                    <Typography className="cookieText">
                        Please let us know if the personal information that we hold about you needs to be corrected or updated.
                    </Typography>

                    <Typography variant="h4" className="cookieSubtitle">
                        K. Cookies
                    </Typography>
                    <Typography className="cookieText">
                        What they are and how we use them
                    </Typography>
                    <Typography className="cookieText">
                        Cookies are little text files which are stored on the browser or hard drive of your computer or mobile device when you visit a webpage or application. Cookies and similar technologies (like local storage, pixels and tags) work to make your browsing experience as smooth as possible and they remember your preferences so you don't have to insert your details again and again.
                    </Typography>
                    <Typography className="cookieText">
                        Some cookies we use are essential for some services to work, others are used to collect information of the use of the website (statistics) so that we can make the site more convenient and useful for you. Some cookies are temporary and will disappear when you close your browser, others are persistent and will stay on your computer for some time. We also use some cookies that are tied to a marketing or advertising campaign that will last for a fixed period of time and the cookies will not be used after the campaign ends. Some of the cookies are strictly necessary for the technical functioning of the site while others are used to enhance the performance, your user experience and enable us to show you relevant advertising and content about Indicio.
                    </Typography>
                    <Typography className="cookieText">
                        There are different types of cookies. Some cookies are placed by our website and others may be placed by third parties. Third party cookies either collect data on behalf of us (like analytics or other technical service providers) or also collect data for their own purposes (like advertising and social media cookies).
                    </Typography>
                    <Typography component="div">
                        <ol className="cookieList">
                            <li>Most browsers allow you to refuse to accept cookies-for example:
                                <ul className="cookieListSecondary">
                                    <li>a. in Internet Explorer (version 10) you can block cookies using the cookie handling override settings available by clicking "Tools," "Internet Options," "Privacy," and then "Advanced";</li>
                                    <li>b. in Firefox (version 24) you can block all cookies by clicking "Tools," "Options," "Privacy," selecting "Use custom settings for history" from the drop-down menu, and unticking "Accept cookies from sites"; and</li>
                                    <li>c. in Chrome (version 29), you can block all cookies by accessing the "Customize and control" menu, and clicking "Settings," "Show advanced settings," and "Content settings," and then selecting "Block sites from setting any data" under the "Cookies" heading.</li>
                                </ul>
                            </li>
                        </ol>
                    </Typography>
                    <Typography className="cookieText">
                        Blocking all cookies will have a negative impact upon the usability of many websites.
                    </Typography>
                    <Typography component="div">
                        <ol className="cookieList">
                            <li>You can delete cookies already stored on your computer-for example:
                                <ul className="cookieListSecondary">
                                    <li>a. in Internet Explorer (version 10), you must manually delete cookie files (you can find instructions for doing so at http://support.microsoft.com/kb/278835 );</li>
                                    <li>b. in Firefox (version 24), you can delete cookies by clicking "Tools," "Options," and "Privacy", then selecting "Use custom settings for history", clicking "Show Cookies," and then clicking "Remove All Cookies"; and</li>
                                    <li>c. in Chrome (version 29), you can delete all cookies by accessing the "Customize and control" menu, and clicking "Settings," "Show advanced settings," and "Clear browsing data," and then selecting "Delete cookies and other site and plug-in data" before clicking "Clear browsing data."</li>
                                </ul>
                            </li>
                        </ol>
                    </Typography>

                    <Typography component="div">
                        <ol className="cookieList">
                            <li>Deleting cookies will have a negative impact on the usability of many websites.</li>
                        </ol>
                    </Typography>
                </div>
            </Container >
            <div className="bottomShape">
                </div>
        </Container>
    )
}
