import React, { Fragment, useEffect, useState } from 'react'
import { ACCESS_TOKEN } from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { authSlice } from './redux-store/auth-slice'
import { httpClient } from './http/http-client'
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer'
import Router from './components/Router'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'
import { tagSlice } from './redux-store/tag-slice'
import CookieNotice from './components/CookieNotice'
import Cookies from 'js-cookie'

const dark = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#5f8685',
      light: '#8db6b5',
      dark: '#335958',
    },
    secondary: {
      main: '#d37e5f',
      light: '#ffae8d',
      dark: '#9e5134',
    },
  },
}

const light = {
  palette: {
    mode: 'light',
    primary: {
      main: '#5f8685',
      light: '#8db6b5',
      dark: '#335958',
    },
    secondary: {
      main: '#d37e5f',
      light: '#ffae8d',
      dark: '#9e5134',
    },
  },
}

export default function App() {
  const mode = useSelector((state) => state.theme.mode)
  const dispatch = useDispatch()
  const [acceptCookie, setAcceptCookie] = useState(Cookies.get("accept-cookie") === 'true');

  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN)
    if (!accessToken) return
    httpClient
      .get('/user/me', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(({ data }) => {
        dispatch(
          authSlice.actions.login({
            currentUser: data,
            token: accessToken,
          }),
        )
        if (data.role === 'WORK_OWNER') {
          httpClient
            .get('/question-tags/work', {
              headers: { Authorization: `Bearer ${accessToken}` },
            })
            .then(({ data: tags }) => {
              dispatch(tagSlice.actions.add(tags))
            })
        } else {
          console.log('implement life tags fetch')
        }
        localStorage.setItem(ACCESS_TOKEN, accessToken)
      })
      .catch((err) => {
        localStorage.removeItem(ACCESS_TOKEN)
      })
  }, [dispatch])

  console.log(acceptCookie)
  return (
    <Fragment>
      <ThemeProvider
        theme={mode === 'light' ? createTheme(light) : createTheme(dark)}
      >
        <CssBaseline />
        <Navbar />
        <Router />
        <Footer />
        {!acceptCookie &&
          <CookieNotice />
        }
      </ThemeProvider>
    </Fragment>
  )
}
