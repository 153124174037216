import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { httpClient } from '../http/http-client'
import { passwordResetEmail } from '../util/email-utils'

export default function AddWorkProvider() {
  const initialFormValues = {
    name: '',
    email: '',
    provider: '',
    companyName: '',
    domain: '',
    loginURL: '',
  }
  const [formValues, setFormValues] = useState(initialFormValues)
  const handleInputChange = (event) => {
    const target = event.target
    const inputName = target.name
    const inputValue = target.value

    setFormValues((fv) => ({
      ...fv,
      [inputName]: inputValue,
    }))
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault()
    httpClient.post("/work-provider/add", formValues).then((res) => {
      passwordResetEmail(formValues.email ,res.data.message).then((r) => {
        console.log("success")
      })
    })
  }
  return (
    <Container maxWidth="sm" sx={{ margin: 2 }}>
      <Paper style={{ padding: 40, width: '100%' }}>
        <form onSubmit={handleFormSubmit} method="POST">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            // sx={{ p: 4 }}
          >
            <Grid item xs={12}>
              <Typography variant="h4" align="center" color="primary">
                Add new provider
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="name"
                label="Name"
                value={formValues.name}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="email"
                name="email"
                label="Email"
                value={formValues.email}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="provider"
                label="Provider"
                value={formValues.provider}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="companyName"
                label="Company Name"
                value={formValues.companyName}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="domain"
                label="Domain Name"
                value={formValues.domain}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                name="loginURL"
                label="Login URL"
                value={formValues.loginURL}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setFormValues(initialFormValues)}
                sx={{ width: '100%' }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleFormSubmit}
                sx={{ width: '100%' }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}
