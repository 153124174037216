import { Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux'
// import Login from './Login'
import { useSnackbar } from 'notistack'
// import Widget from 'rasa-webchat';

import "./Home/style.css"
import { NoEncryption } from '@mui/icons-material';

export default function Home({ location }) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (location.state && location.state.error) {
      return enqueueSnackbar(location.state.error, { variant: 'error' })
    }
  }, [location.state, enqueueSnackbar]);
  const auth = useSelector(state => state.auth);
  return (
    <Container className="homeWrapper">
      <Container maxWidth="lg" sx={{ pt: 5 }}>
        <Grid container className="gridWrapper" sx={{ justifyContent: 'center' }}>
          <Grid className="homeText">
            <Typography variant='h2' className="homeTitle">Welcome to Freudas!</Typography>
            {auth && auth.token && <QRCode value={auth.token} size={200} />}
            <Typography variant='h4' className="homeSubtitle">The employee platform for wellbeing and performance.</Typography>
          </Grid>
          {/* <Grid className="homeLogin" item xs={6}>
            <Grid container alignItems="center" justifyContent="center" sx={{ height: '60vh' }} >
              <Login />
            </Grid>
          </Grid> */}
        </Grid>
        {/* <Widget
        initPayload={"/greet"}
        socketUrl={"https://freudas-chatbot.herokuapp.com/"}
        socketPath={"/socket.io/"}
        customData={{"language": "en"}} // arbitrary custom data. Stay minimal as this will be added to the socket
        title={"Freudas"}
        // connectOn={'open'}
        // embedded={true}
      /> */}
      </Container>
    </Container>
  )
}
