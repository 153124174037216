import { createSlice } from '@reduxjs/toolkit';

export const tagSlice = createSlice({
  name: 'tags',
  initialState: {
    list: []
  },
  reducers: {
    add: (state, action) => {
      state.list = action.payload
    },
    remove: (state) => {
      state.list = []
    },
  },
})
