import React from 'react'
import { ACCESS_TOKEN } from '../constants'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { httpClient } from '../http/http-client'
import { authSlice } from '../redux-store/auth-slice'
import { tagSlice } from '../redux-store/tag-slice'

export default function OAuth2RedirectHandler(props) {
  const dispatch = useDispatch()
  const getUrlParameter = (name) => {
    //eslint-disable-next-line
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')

    var results = regex.exec(props.location.search)
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }

  const token = getUrlParameter('token')
  const error = getUrlParameter('error')

  // console.log(token)

  if (token) {
    httpClient
      .get('/user/me', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch(
          authSlice.actions.login({
            currentUser: data,
            token: token,
          }),
        )
        localStorage.setItem(ACCESS_TOKEN, token)
      })
    httpClient
      .get('/question-tags/work', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch(tagSlice.actions.add(data))
      })
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: {
            from: props.location,
            error: error,
          },
        }}
      />
    )
  }
}
