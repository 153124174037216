import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ACCESS_TOKEN, GOOGLE_AUTH_URL } from '../constants'
import { authSlice } from '../redux-store/auth-slice'
import { httpClient } from '../http/http-client'
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import Signup from './Signup'
import { tagSlice } from '../redux-store/tag-slice'
import { passwordResetEmail } from '../util/email-utils'
import { useSnackbar } from 'notistack'

const modalStyle = {
  '&:focus': {
    outline: 'none',
  },
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
}

export default function Login() {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [user, setUser] = useState({
    email: '',
    password: '',
  })
  const handleInputChange = (event) => {
    const target = event.target
    const inputName = target.name
    const inputValue = target.value

    setUser((u) => ({
      ...u,
      [inputName]: inputValue,
    }))
  }
  const handleSubmit = (event) => {
    event.preventDefault()

    httpClient
      .post('/auth/login', user)
      .then((res) => {
        const { accessToken } = res.data
        httpClient
          .get('/user/me', {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then(({ data }) => {
            dispatch(
              authSlice.actions.login({
                currentUser: data,
                token: accessToken,
              }),
            )
            if (data.role === 'WORK_OWNER') {
              httpClient
                .get('/question-tags/work', {
                  headers: { Authorization: `Bearer ${accessToken}` },
                })
                .then(({ data: tags }) => {
                  dispatch(tagSlice.actions.add(tags))
                })
            } else {
              console.log('implement life tags fetch')
            }
            localStorage.setItem(ACCESS_TOKEN, accessToken)
          })
      })
      .catch((error) => {
        console.log(
          (error && error.message) ||
            'Oops! Something went wrong. Please try again!',
        )
      })
  }
  const handlePasswordReset = () => {
    //eslint-disable-next-line
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email)) {
      return enqueueSnackbar('Provide valid email!', { variant: 'error' })
    }
    httpClient
      .post(`/auth/forgot-password/${user.email}`, {})
      .then(({ data }) => {
        passwordResetEmail(user.email, data.message)
        enqueueSnackbar('Reset link sent on your email!', {
          variant: 'success',
        })
      })
      .catch((error) => {
        enqueueSnackbar('Provide valid email!', { variant: 'error' })
      })
  }
  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: 2,
        width: 300,
        p: 4,
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Typography variant="h5" color="primary" sx={{ mb: 2 }}>
              Login to Freudas
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyle}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ mb: 2 }}
                align="center"
              >
                Create New Account
              </Typography>
              <Signup closeModal={handleClose} />
            </Box>
          </Modal>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
              // sx={{ p: 4 }}
            >
              <Grid item xs={12}>
                <TextField
                  type="email"
                  name="email"
                  label="Email"
                  value={user.email}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="password"
                  name="password"
                  label="Password"
                  value={user.password}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignContent="center"
                >
                  <Typography onClick={handleOpen}>
                    <span
                      style={{
                        textDecoration: 'underline',
                        color: theme.palette.secondary.main,
                      }}
                    >
                      Sign Up
                    </span>{' '}
                    instead?
                  </Typography>
                  <Button type="submit" variant="contained" color="secondary">
                    Login
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            Have work account?{' '}
            <a
              style={{
                textDecoration: 'underline',
                color: theme.palette.secondary.main,
              }}
              href={GOOGLE_AUTH_URL}
            >
              Click here
            </a>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            Forgot your password?{' '}
            <span
              style={{
                textDecoration: 'underline',
                color: theme.palette.secondary.main,
              }}
              onClick={handlePasswordReset}
            >
              Click here
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
