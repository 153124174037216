import { Button, Grid, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
// import { useHistory } from 'react-router-dom'
import { httpClient } from '../http/http-client'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import { signupEmail } from '../util/email-utils'
import { useSnackbar } from 'notistack'

export default function Signup({ closeModal }) {
  const { enqueueSnackbar } = useSnackbar()
  // const history = useHistory()
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    password: '',
    imageUrl: '',
  })

  const handleInputChange = (event) => {
    const target = event.target
    const inputName = target.name
    const inputValue = target.value

    setFormValues((fv) => ({
      ...fv,
      [inputName]: inputValue,
    }))
  }
  const handleUploadClick = (event) => {
    var file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function (e) {
      setFormValues((s) => ({ ...s, imageUrl: reader.result }))
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', formValues.imageUrl)
    formData.append('upload_preset', 'freudas')
    formData.append('cloud_name', 'pratik-savlastore')

    const url = 'https://api.cloudinary.com/v1_1/pratik-savlastore/image/upload'
    fetch(url, { method: 'POST', body: formData })
      .then((res) => res.json())
      .then(({ secure_url }) => {
        httpClient
          .post('/auth/signup', { ...formValues, imageUrl: secure_url })
          .then(({ data }) => {
            try {
              signupEmail(formValues.email, formValues.name, data.message)
              enqueueSnackbar('Verification email sent!', {
                variant: 'success',
              })
            } catch (e) {
              enqueueSnackbar('Invalid email!', {
                variant: 'error',
              })
            }
            closeModal()
          })
          .catch((error) => {
            enqueueSnackbar(
              (error && error.message) ||
                'Oops! Something went wrong. Please try again with different email!',
              { variant: 'error' },
            )
          })
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        // sx={{ p: 4 }}
      >
        <Grid item xs={12}>
          <TextField
            type="text"
            name="name"
            label="Name"
            value={formValues.name}
            onChange={handleInputChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            name="email"
            label="Email"
            value={formValues.email}
            onChange={handleInputChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            name="password"
            label="Password"
            value={formValues.password}
            onChange={handleInputChange}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" alignContent="center">
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleUploadClick}
            />
            <label htmlFor="contained-button-file">
              <IconButton component="span">
                <AddPhotoAlternateIcon />
              </IconButton>
            </label>
            {/* <input
              accept="image/*"
              // className={classes.input}
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
            />
            <label htmlFor="raised-button-file">
              <IconButton variant="contained" color="secondary">
                <AddPhotoAlternateIcon />
              </IconButton>
            </label> */}
            <Button
              onClick={closeModal}
              variant="contained"
              color="primary"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="secondary">
              Signup
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
