// export const API_BASE_URL = 'http://localhost:8080';
// export const ACCESS_TOKEN = 'accessToken';

// export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect'

export const API_BASE_URL = 'https://freudas-backend.herokuapp.com';
export const ACCESS_TOKEN = 'accessToken';

export const OAUTH2_REDIRECT_URI = 'https://freudas-webapp.herokuapp.com/oauth2/redirect'


export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;