import {
  Button,
  Chip,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { httpClient } from '../../http/http-client'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Pie,
  PieChart,
} from 'recharts'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

export default function WorkOwnerHome() {
  const theme = useTheme()
  const user = useSelector((state) => state.auth.currentUser)
  const tags = useSelector((state) => state.tags.list)
  const [selectedTags, setSelectedTags] = useState([])
  const [subGraphData, setSubgraphData] = useState([])
  const [currYearScores, setCurrYearScores] = useState([])
  const [subChartCount, setSubChartCount] = useState(0)
  var d = new Date()
  d.setMonth(d.getMonth() + 1)
  const [data, setData] = useState(
    [...Array(12).keys()]
      .map((val) => ({
        id: val,
        name: new Date(d.setMonth(d.getMonth() + 1)).toLocaleString('default', {
          month: 'short',
        }),
        value: 0,
      }))
      .reverse(),
  )
  useEffect(() => {
    setSelectedTags(
      tags.map((item) => ({
        ...item,
        isSelected: true,
      })),
    )
  }, [tags])
  useEffect(() => {
    if (!user || (user && user.role !== 'WORK_OWNER')) return
    // d = new Date()
    async function fetchAna() {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth()
      try {
        setCurrYearScores([])
        for (var i = 0; i < 12; i++) {
          const { data } = await httpClient.get(
            `/work-provider/analysis?fromDate=${new Date(
              y,
              m - i,
              1,
            ).toISOString()}&toDate=${new Date(y, m + 1 - i, 0).toISOString()}`,
          )
          setCurrYearScores((s) => [data, ...s])
        }
      } catch (err) {
        console.log('error')
      }
    }
    fetchAna()
  }, [user])
  useEffect(() => {
    if (currYearScores.length < 12) return
    var dd = new Date()
    setData(
      currYearScores.map((obj, index) => ({
        id: index,
        name: new Date(dd.setMonth(dd.getMonth() + 1)).toLocaleString('default', {
          month: 'short',
        }),
        value: avg(
          selectedTags
            .filter((item) => item.isSelected)
            .map((selTag) => obj.analysis[selTag.id].avg),
        ),
      })),
    )
    setSubgraphData(
      selectedTags
        .map((tag) => {
          const yearData = currYearScores.map((obj) => obj.analysis[tag.id].avg).slice(0,-1)
          const average = avg(yearData)
          if (average === 0) return null
          const currMonth = currYearScores.slice(-1)[0].analysis[tag.id].avg
          return {
            ...tag,
            value: Math.round((currMonth / average) * 10000 - 10000) / 100,
            data: [
              {
                name: 'This Month',
                value: currMonth,
                fill: theme.palette.secondary.main,
              },
              {
                name: 'This Year',
                value: average,
                fill: theme.palette.primary.main,
              },
            ],
          }
        })
        .filter((item) => !!item),
    )
  }, [selectedTags, currYearScores,theme])
  const handleTagClick = (tagId) => {
    setSelectedTags((ts) =>
      ts.map((t) => (t.id !== tagId ? t : { ...t, isSelected: !t.isSelected })),
    )
  }
  const handleUnSelectAll = () => {
    setSelectedTags((tgs) =>
      tgs.map((item) => ({
        ...item,
        isSelected: false,
      })),
    )
  }
  const handleSelectAll = () => {
    setSelectedTags((tgs) =>
      tgs.map((item) => ({
        ...item,
        isSelected: true,
      })),
    )
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 5, pb: 5 }}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h4">Welcome, {user.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Chart filters</Typography>
          <Chip
            style={{ margin: 2 }}
            label="Clear All"
            color="secondary"
            onClick={handleUnSelectAll}
          />
          <Chip
            style={{ margin: 2 }}
            label="Select All"
            color="secondary"
            onClick={handleSelectAll}
          />
          {selectedTags.map((tag) => {
            return (
              <Chip
                style={{ margin: 2 }}
                key={tag.id}
                label={tag.tag}
                color={tag.isSelected ? 'primary' : 'default'}
                onClick={handleTagClick.bind(this, tag.id)}
                deleteIcon={!tag.isSelected ? <CloseIcon /> : <DoneIcon />}
                onDelete={handleTagClick.bind(this, tag.id)}
              />
            )
          })}
        </Grid>
        <Grid item xs={12} width="100%" height={500}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis type="number" domain={[0, 100]} hide />
              <Tooltip />
              <Line
                strokeWidth={2}
                type="monotone"
                dataKey="value"
                stroke={theme.palette.primary.main}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {subGraphData.slice(0, subChartCount).map((tag) => (
              <SubChart key={tag.id} tag={tag} />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Button onClick={() => setSubChartCount((s) => s + 3)}>
              Show More
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
function SubChart({ tag }) {
  return (
    <Grid item width="100%" xs={4}>
      <Paper style={{ padding: 20, height: '30vh' }}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Typography variant='h6'>{tag.tag}:</Typography>
          <Grid item>
            <Grid container alignItems="center">
            {tag.value >= 0 ? (
                <ArrowDropUpIcon sx={{ fontSize: 30 }} color="success"/>
              ) : (
                <ArrowDropDownIcon sx={{ fontSize: 30 }} color="error"/>
              )}
              <Typography
                variant='h6'
                color={tag.value >= 0 ? 'success.main' : 'error.main'}
              >
                {tag.value}%
              </Typography>
              
            </Grid>
          </Grid>
        </Grid>
        <ResponsiveContainer width="100%" height="90%">
          <PieChart width="60%" height="60%">
            <Tooltip />
            <Pie
              data={tag.data}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={50}
            />
          </PieChart>
        </ResponsiveContainer>
      </Paper>
    </Grid>
  )
}

// const sum = (list) =>
//   list.reduce(function (a, b) {
//     return a + b
//   }, 0)

const avg = (list) => {
  let sum = 0,
    count = 0
  list.forEach((item) => {
    sum += item > 0 ? item : 0
    count += item > 0 ? 1 : 0
  })
  return Math.round((sum / (count > 0 ? count : 1)) * 100) / 100
}
