import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth-slice';
import { tagSlice } from './tag-slice';
import { themeSlice } from './theme-slice';

export const appStore = configureStore({
  reducer: {
    auth: authSlice.reducer,
    theme: themeSlice.reducer,
    tags: tagSlice.reducer,
  },
});