import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { appStore } from './redux-store/store'
import { Provider } from 'react-redux'
import '@fontsource/roboto'
import { SnackbarProvider } from 'notistack'

// const colors = {
//   1: '#5F8685', // primary 1
//   2: '#E5E0A9', // primary 2
//   3: '#F8F5E8', // background
//   4: '#EDB4A2', // secondary 2
//   5: '#D37E5F', // secondary 1
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={appStore}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Router>
          <App />
        </Router>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// JUST client-side redirect to https
if(!window.location.href.toLowerCase().startsWith("https") && window.location.href.indexOf("localhost")===-1){
  window.location = window.location.href.replace("http://", "https://");
}
