import { Box, Container, Link, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ReactComponent as FacebookIcon } from "./assets/Facebook.svg";
import { ReactComponent as InstagramIcon } from "./assets/Instagram.svg";
import { ReactComponent as LinkedinIcon } from "./assets/LinkedIn.svg";
import { ReactComponent as TwitterIcon } from "./assets/Twitter.svg";

const useStyles = makeStyles(() => {
    return {
        footer: {
            maxWidth:"100%",
            width:"100%",
            display:"flex",
            flexFlow:"column",
            justifyContent:"center",
            alignItems:"center",
            padding:"30px 0",
            boxShadow:"0px 0px 10px 0px rgba(0,0,0,0.45)"
            

        },
        social: {
            display:"flex",
            flexWrap:"wrap",
            justifyContent:"center"
        },
        socialLink: {
            margin:"0 10px",
        },
        footerLinks: {
            display:"flex",
            marginTop:"25px",
            flexWrap:"wrap",
            justifyContent:"center"
        },
        footerLink: {
            margin:"0 15px",
        },
        copyright: {
            marginTop:"18px",
            color:"#5f8685"
        }
    }
})

export default function Footer() {
    const styles = useStyles();

    return (
        <Container className={styles.footer}>
            <Box className={styles.social}>
                <Link href="https://www.instagram.com/freudasofficial/" target="_blank" underline="none" className={styles.socialLink}>
                    <InstagramIcon width={40}  />
                </Link>
                <Link href="https://www.linkedin.com/company/freudas/about/" target="_blank" underline="none" className={styles.socialLink}>
                    <LinkedinIcon width={40} />
                </Link>
                <Link href="https://twitter.com/FreudasOfficial" target="_blank" underline="none" className={styles.socialLink}>
                    <TwitterIcon width={40} />
                </Link>
                <Link href="https://www.facebook.com/FreudasOfficial" target="_blank" underline="none" className={styles.socialLink}>
                    <FacebookIcon width={40} />
                </Link>
            </Box>
            <Box className={styles.footerLinks}>
                <Link href="/" underline="none" className={styles.footerLink}>
                    Home
                </Link>
                <Link href="/about" underline="none" className={styles.footerLink}>
                    About
                </Link>
                <Link href="/contact" underline="none" className={styles.footerLink}>
                    Contact
                </Link>
                <Link href="/contact" underline="none" className={styles.footerLink}>
                    Partner
                </Link>
                <Link href="/cookies" underline="none" className={styles.footerLink}>
                    Cookies
                </Link>
            </Box>
            <Box className={styles.copyright}>
                <Typography variant="span">Freudas © 2023</Typography>
            </Box>
        </Container>
    );
}