import {
  AppBar,
  Grid,
  Button,
  Toolbar,
  Typography,
  useTheme,
  Tabs,
  Tab,
  IconButton
} from '@mui/material'
import * as React from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import WbSunnyIcon from '@mui/icons-material/WbSunny'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import { themeSlice } from '../../redux-store/theme-slice'
import { authSlice } from '../../redux-store/auth-slice'

import "./style.css"

function useRouteMatch(patterns) {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}

function LoggedOutTabs() {
  const routeMatch = useRouteMatch([
    '/contact',
    '/about',
    '/',
  ])
  const currentTab = routeMatch?.path ? routeMatch.path : "/"
  return (
    <Tabs value={currentTab}>
      <Tab label="Home" value="/" to="/" component={Link} />
      <Tab label="About" value="/about" to="/about" component={Link} />
      <Tab label="Contact Us" value="/contact" to="/contact" component={Link} />
    </Tabs>
  )
}
function AdminTabs({ handleLogout }) {
  const routeMatch = useRouteMatch(['/'])
  const currentTab = routeMatch?.path ? routeMatch.path : "/"
  return (
    <Tabs value={currentTab}>
      <Tab label="Home" value="/" to="/" component={Link} />
      <Tab
        label="Logout"
        value={false}
        onClick={handleLogout}
        component={Button}
      />
    </Tabs>
  )
}
function WorkUserTabs({ handleLogout }) {
  const routeMatch = useRouteMatch(['/contact', '/about', '/'])
  const currentTab = routeMatch?.path ? routeMatch.path : "/"
  return (
    <Tabs value={currentTab}>
      <Tab label="Home" value="/" to="/" component={Link} />
      <Tab label="About" value="/about" to="/about" component={Link} />
      <Tab label="Contact Us" value="/contact" to="/contact" component={Link} />
      <Tab
        label="Logout"
        value={false}
        onClick={handleLogout}
        component={Button}
      />
    </Tabs>
  )
}

function LifeUserTabs({ handleLogout }) {
  const routeMatch = useRouteMatch(['/contact', '/about', '/'])
  const currentTab = routeMatch?.path ? routeMatch.path : "/"
  return (
    <Tabs value={currentTab}>
      <Tab label="Home" value="/" to="/" component={Link} />
      <Tab label="About" value="/about" to="/about" component={Link} />
      <Tab label="Contact Us" value="/contact" to="/contact" component={Link} />
      <Tab
        label="Logout"
        value={false}
        onClick={handleLogout}
        component={Button}
      />
    </Tabs>
  )
}
function WorkOwnerTabs({ handleLogout }) {
  const routeMatch = useRouteMatch(['/contact', '/about', '/'])
  const currentTab = routeMatch?.path ? routeMatch.path : "/"
  return (
    <Tabs value={currentTab}>
      <Tab label="Home" value="/" to="/" component={Link} />
      <Tab label="About" value="/about" to="/about" component={Link} />
      <Tab label="Contact Us" value="/contact" to="/contact" component={Link} />
      <Tab
        label="Logout"
        value={false}
        onClick={handleLogout}
        component={Button}
      />
    </Tabs>
  )
}
export default function Navbar() {
  const dispatch = useDispatch()
  const mode = useSelector((state) => state.theme.mode)
  const auth = useSelector((state) => state.auth)
  const theme = useTheme()
  const handleLogout = () => {
    dispatch(authSlice.actions.logout())
    localStorage.removeItem('accessToken')
  }
  const switchTheme = () => {
    dispatch(themeSlice.actions.switch())
  }

  return (
    <AppBar
      // position="static"
      style={{ backgroundColor: theme.palette.background.paper }}
      position="sticky"
    >
      <Toolbar>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ mr: 10, ml: 10 }}
          className='nav-wrapper'
        >
          <Grid item className='logo'>
          <img src="header-logo.png" alt="logo"/>
            <Typography variant="h6" component="div" color="primary">
              Freudas
            </Typography>
          </Grid>
          <Grid item className='nav-items'>
            <Grid container alignItems="center" justifyContent="center">
              {!auth.authenticated && <LoggedOutTabs />}
              {auth.currentUser && auth.currentUser.role === 'ADMIN_USER' && (
                <AdminTabs handleLogout={handleLogout} />
              )}
              {auth.currentUser && auth.currentUser.role === 'LIFE_USER' && (
                <LifeUserTabs handleLogout={handleLogout} />
              )}
              {auth.currentUser && auth.currentUser.role === 'WORK_USER' && (
                <WorkUserTabs handleLogout={handleLogout} />
              )}
              {auth.currentUser && auth.currentUser.role === 'WORK_OWNER' && (
                <WorkOwnerTabs handleLogout={handleLogout} />
              )}
              <IconButton
                color="primary"
                component="span"
                onClick={switchTheme}
              >
                {mode === 'dark' ? <WbSunnyIcon /> : <DarkModeOutlinedIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
