import React, { useState } from 'react'
import "./style.css"
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Container, Link, Typography, useTheme } from '@mui/material'

import { ReactComponent as PhoneIcon } from "./assets/Phone.svg";
import { ReactComponent as LocationIcon } from "./assets/Location.svg";

export function ContactUs() {
  const theme = useTheme();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    selection: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    message: ""
  });

  const resetForm = () => {
    setFormData({
      selection: "",
      name: "",
      surname: "",
      email: "",
      phone: "",
      message: ""
    });
  };

  const onSubmit = async (event) => {
    setLoading(true);
    setSuccess(false);
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "c9f1c1eb-53cd-4719-a019-64a2a653a3f5");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      setSuccess(true);
      setLoading(false);
      resetForm(); // Reset the form after successful submission
    }
  };

  return (
    <div className="contactWrapper">
      <Container className="contactContent">
        <Typography variant="h3" className="contactTitle">
          Contact Us
        </Typography>
        <div>
          <Typography className="contactText">
            If you have any questions or queries, a member of staff will always be happy to help. You can contact us by telephone or email, and we will be sure to get back to you promptly.
          </Typography>
        </div>
        <div className="boxesWrapper">
          <div className="contactLeft">
            <Typography variant="h4" className="secondTitle">
              Our office
            </Typography>
            <Typography variant="h4" className="cityTitle">
              STOCKHOLM
            </Typography>
            <Link href="tel:+46733534555" underline="none" className="phone" sx={{ color: theme.palette.mode === 'light' ? '#000' : '#fff' }}>
              <PhoneIcon width={45} />Give us a call at <strong>+46733534555</strong>
            </Link>
            <div className="address">
              <LocationIcon width={45} /> <strong>Mössebergsvägen 1 16743</strong>
            </div>
            <div className="cityImage"></div>
          </div>
          <div className="contactRight">
            <Typography variant="h4" className="secondTitle">
              Get in touch!
            </Typography>
            <form onSubmit={onSubmit}>
              <select className="full" id="selection" name="selection" value={formData.selection} onChange={(e) => setFormData({ ...formData, selection: e.target.value })}>
                <option value="" disabled selected>
                  - Please choose an option -
                </option>
                <option value="sales">I want to talk with sales</option>
                <option value="question">I am a customer and I have a question</option>
                <option value="partner">I would like to become a Freudas partner</option>
                <option value="other">I have a different question</option>
              </select>
              <input className="half required-input" type="text" placeholder="Name *" name="name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
              <input className="half" type="text" placeholder="Surname *" name="surname" value={formData.surname} onChange={(e) => setFormData({ ...formData, surname: e.target.value })} required />
              <input className="half" type="email" placeholder="Email *" name="email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
              <input className="half" type="phone" placeholder="Phone *" name="phone" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} required />
              <textarea className="full" name="message" placeholder="Message" value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })}></textarea>
              <div className='terms'>
                <input type="checkbox" id="terms" name="terms" checked={checked} onClick={() => setChecked(!checked)} />
                <label htmlFor="terms">I have read and agreed with the <a href="#" style={{ color: theme.palette.mode === 'light' ? '#000' : '#fff' }}>terms and conditions.</a></label>
              </div>
              <div className="formBottom">
                <div>
                  <span className="red">*</span>
                  <span>Required fields</span>
                </div>
                <div className="buttons">
                  <LoadingButton
                    type="submit"
                    variant="outlined"
                    className='submitButton'
                    loading={loading}
                    disabled={!checked}
                    style={{ color: theme.palette.mode === 'light' ? '#000' : '#000' }}
                  >
                    Submit
                  </LoadingButton>
                  <Button
                    variant="contained"
                    onClick={resetForm}
                    className='cancelButton'
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
            {success &&
              <Alert severity="success">Thank you! Your form has been submitted.</Alert>
            }
          </div>
        </div>
      </Container>
    </div>
  );
}


