import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { httpClient } from '../http/http-client'
import PasswordStrengthBar from 'react-password-strength-bar'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

export default function ResetPassword({location}) {
  const { enqueueSnackbar } = useSnackbar()
  const  { search, pathname } = location;
  var params = {};
  search.substring(2).split("&").foreach(rec => {
    params[rec.split("=")[0]] = rec.split("=")[1]
  })
  if (pathname === "/verify-user" && params.mail && params.key) {
    httpClient.get(`/auth/verify-email?email=${params.mail}&resetKey=${params.key}`).then(res => {
      enqueueSnackbar('Email verified. Login now!', { variant: 'success' })
      history.push('/')
    }).catch((err) => {
      enqueueSnackbar('Bad request!', { variant: 'error' })
    })
  }
  const history = useHistory()
  const initialFormValues = {
    email: params.mail ? params.mail :'',
    password: '',
    confirmPassword: '',
    resetKey: params.key ? params.key :'',
  }
  const [formValues, setFormValues] = useState(initialFormValues)
  const handleInputChange = (event) => {
    const target = event.target
    const inputName = target.name
    const inputValue = target.value

    setFormValues((fv) => ({
      ...fv,
      [inputName]: inputValue,
    }))
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault()
    httpClient.post('/auth/reset-password', formValues).then((res) => {
      console.log('success')
        enqueueSnackbar('Reset Successfull. Login now!', { variant: 'success' })
        history.push('/')
    }).catch((error) => {
      enqueueSnackbar('Provide valid details!', { variant: 'error' })
    })
  }
  return (
    <Container maxWidth="sm" sx={{ marginTop: 5 }}>
      <Paper style={{ padding: 40, width: '100%' }}>
        <form onSubmit={handleFormSubmit} method="POST">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="h4" align="center" color="primary">
                Add new provider
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="email"
                name="email"
                label="Email"
                value={formValues.email}
                onChange={handleInputChange}
                required
                fullWidth
                disabled={!!params.mail}
              />
              <Typography color="error">&nbsp;</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                name="password"
                label="Password"
                value={formValues.provider}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <PasswordStrengthBar password={formValues.password} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                value={formValues.companyName}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <Typography color="error">
                {formValues.password !== formValues.confirmPassword &&
                  'Passwords are not matching'}
                &nbsp;
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                name="resetKey"
                label="Reset Key"
                value={formValues.resetKey}
                onChange={handleInputChange}
                required
                fullWidth
                disabled={!!params.key}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setFormValues(initialFormValues)}
                sx={{ width: '100%' }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleFormSubmit}
                sx={{ width: '100%' }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}
