import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authenticated: false,
    currentUser: null,
    token: null,
  },
  reducers: {
    login: (state, action) => {
      state.authenticated = true
      state.currentUser = action.payload.currentUser
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.authenticated = false
      state.currentUser = null
      state.token = null;
    },
  },
})
