import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    mode: 'light',
  },
  reducers: {
    switch: (state) => {
      state.mode = state.mode === 'light' ? 'dark':'light'
    },
  },
})
