import React from 'react'
import "./style.css"
import { useTheme } from '@mui/material'

export default function About() {
    const theme = useTheme();

    return (

        <body>
            <div className="top-wrapper">
                <div className='container'>
                    <div className="row left">
                        <div className="col" style={{ backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.45)' : 'rgba(84, 84, 84, 0.45)' }}>
                            <h1 className="heading">70%</h1>
                            <p>of employees are struggling or suffering, in their overall lives</p>
                            <span>Gallup</span>
                        </div>
                        <div className="col" style={{ backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.45)' : 'rgba(84, 84, 84, 0.45)' }}>
                            <h1 className="heading">40%</h1>
                            <p>of people are unhappy at work and consider leaving in the near future</p>
                            <span>World Economic Forum</span>
                        </div>
                        <div className="col" style={{ backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.45)' : 'rgba(84, 84, 84, 0.45)' }}>
                            <h1 className="heading">13%</h1>
                            <p>of employees are largely satisfied with their work experiences</p>
                            <span>Gartner</span>
                        </div>
                    </div>
                    <div className="row center">
                        <div className="col" style={{ backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.45)' : 'rgba(84, 84, 84, 0.45)' }}>
                            <h1 className="heading">£45bn</h1>
                            <p>cost for employers in UK per year, due to lack of engagement</p>
                            <span>Deloitte</span>
                        </div>
                    </div>
                    <div className="row right">
                        <div className="col" style={{ backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.45)' : 'rgba(84, 84, 84, 0.45)' }}>
                            <h1 className="heading">80%</h1>
                            <p>of employees are not engaged or are actively disengaged at work</p>
                            <span>Gallup</span>
                        </div>
                        <div className="col" style={{ backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.45)' : 'rgba(84, 84, 84, 0.45)' }}>
                            <h1 className="heading">10%</h1>
                            <p>of GDP, US$8.1 trillion, is lost in productivity each year due to lack of engagement at work</p>
                            <span>Gallup</span>
                        </div>
                        <div className="col" style={{ backgroundColor: theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.45)' : 'rgba(84, 84, 84, 0.45)' }}>
                            <h1 className="heading">45%</h1>
                            <p>of HR leaders say their employees are fatigued from all the change</p>
                            <span>Gartner</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="platform">
                <div className='platformTopShape'></div>
                <div className="container">
                    <div className="text">
                        <h1>The employee platform for wellbeing and performance</h1>
                        <p>Freudas B2B SaaS platform explores both your employees' work and personal life experience with the aim to improve wellbeing and performance for both your business and your employees'.</p>
                        <p>Research shows that when employees' wellbeing is thriving, an organization benefits directly. Happy staff will take fewer sick days, deliver higher performance, and have lower rates of burnout and turnover, according to Gallup. But equally, when employees' wellbeing suffers, so does a company's bottom line.</p>
                        <p>Freudas acknowledges that this human centric approach is essential to how your business performs.</p>
                    </div>
                    <div className='image'>
                    </div>
                </div>
                <div className='platformBottomShape'></div>
            </div>
            <div className="work">
                <div className="container">
                    <div className='textWrapper'>
                        <h1>Work pulse surveys</h1>
                        <p>Accumulate data from your employees via quick surveys on their digital device and offer your business consistent feedback and insight on the wellbeing and performance of your team. Use data and trends accessible from our dashboard to make informed decisions on how to improve your business.</p>
                    </div>
                </div>

            </div>
            <div className="personal">
                <div className='personalTopShape'></div>
                <div className="container">
                    <div className="text">
                        <h1>Personal pulse surveys</h1>
                        <p>Your employees' can from quick surveys related to their own personal life, with privacy in mind, build their psychological personal profile. With the use of AI, Freudas platform distribute science-based content tailored to a users' personal profile for everyday support, wellbeing and for personal long-term growth. Freudas use scientific based content with intention on overcoming employees' core problems, to enable long term wellbeing, rather than symptom relieving content and tools with temporary effect.</p>
                    </div>
                    <img alt="dashboard" src="app-dahsboard.webp" />
                </div>
                <div className='personalBottomShape'></div>
            </div>
            <div className="grow">
                <div className="container">
                    <div className="text">
                        <h1>We help employees and organizations reach their full potential.</h1>
                    </div>
                </div>
            </div>

        </body>
    )
}
