import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import About from './About'
import AdminHome from './admin/AdminHome'
import {ContactUs} from './ContactUs'
import {Cookies} from './Cookies'
import Home from './Home'
import Login from './Login'
import NotFound from './NotFound'
import OAuth2RedirectHandler from './OAuth2RedirectHandler'
import ResetPassword from './ResetPassword'
import UserHome from './user/UserHome'
import WorkOwnerHome from './work-owner/WorkOwnerHome'

export default function Router() {
  const auth = useSelector((state) => state.auth)
  if (!auth.authenticated) {
    return (
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/about" component={About}></Route>
        <Route exact path="/contact" component={ContactUs}></Route>
        <Route exact path="/cookies" component={Cookies}></Route>
        <Route path="/login" component={Login}></Route>
        <Route path="/reset-password" component={ResetPassword}></Route>
        <Route path="/verify-user" component={ResetPassword}></Route>
        <Route
          path="/oauth2/redirect"
          component={OAuth2RedirectHandler}
        ></Route>
        <Route component={NotFound}></Route>
      </Switch>
    )
  }
  switch (auth.currentUser.role) {
    case 'ADMIN_USER':
      return (
        <Switch>
          <Route exact path="/" component={AdminHome}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/contact" component={ContactUs}></Route>
          <Route exact path="/cookies" component={Cookies}></Route>
          <Route component={NotFound}></Route>
        </Switch>
      )
    case 'WORK_OWNER':
      return (
        <Switch>
          <Route exact path="/" component={WorkOwnerHome}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/contact" component={ContactUs}></Route>
          <Route exact path="/cookies" component={Cookies}></Route>
          <Route component={NotFound}></Route>
        </Switch>
      )
    case 'LIFE_USER':
      return (
        <Switch>
          <Route exact path="/" component={UserHome}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/contact" component={ContactUs}></Route>
          <Route exact path="/cookies" component={Cookies}></Route>
          <Route component={NotFound}></Route>
        </Switch>
      )
    case 'WORK_USER':
      return (
        <Switch>
          <Route exact path="/" component={UserHome}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/contact" component={ContactUs}></Route>
          <Route exact path="/cookies" component={Cookies}></Route>
          <Route component={NotFound}></Route>
        </Switch>
      )
    default:
      return (
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/contact" component={ContactUs}></Route>
          <Route exact path="/cookies" component={Cookies}></Route>
          <Route path="/login" component={Login}></Route>
          <Route path="/reset-password" component={ResetPassword}></Route>
          <Route path="/verify-user" component={ResetPassword}></Route>
          <Route
            path="/oauth2/redirect"
            component={OAuth2RedirectHandler}
          ></Route>
          <Route component={NotFound}></Route>
        </Switch>
      )
  }
}
