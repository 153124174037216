import { useState } from 'react';
import { Backdrop, Box, Button, Container, Typography } from "@mui/material";
import Cookies from 'js-cookie';
import "./style.css"

export default function CookieNotice() {
    const [open, setOpen] = useState(true);

    const handleAcceptCookies = () => {
        Cookies.set("accept-cookie", true);
        setOpen(false);
    };

    return (
        <div>
            <Backdrop
                sx={{ zIndex: 9999, alignItems: 'flex-end', }}
                open={open}
            >
                <Container className="cookiesContainer">
                    <Box className="cookiesText">
                        <Typography>
                            This website uses cookies to ensure you get the best experience on our website. <a className="cookiesLink" href="/cookies">Learn more</a>
                        </Typography>
                    </Box>
                    <Box>
                        <Button className="cookiesBtn"
                            variant='contained'
                            onClick={() => handleAcceptCookies()}
                        >
                            Accept
                        </Button>
                    </Box>
                </Container>
            </Backdrop>
        </div>
    );
}
