import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import QRCode from 'react-qr-code'
import { useSelector } from 'react-redux'

function UserHome() {
  const auth = useSelector((state) => state.auth)
  // console.log(auth)
  return (
    <Container maxWidth="lg" sx={{ pt: 5 }}>
      <Grid container alignItems="flex-start" justifyContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="h3">
            Hi {auth.currentUser.name}, welcome to Freudas!
          </Typography>
          
        </Grid>
        <Grid item xs ={6}>
        <Typography variant="h5" sx={{paddingLeft:'20px', marginTop:'40px', marginBottom:'20px'}}>
            Download and install Freudas mobile app and login by scanning the below QR code.
          </Typography>
          {auth && auth.token && <QRCode value={auth.token} size={200} />}
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserHome
