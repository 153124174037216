import { Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import AddWorkProvider from '../AddWorkProvider'

export default function AdminHome() {
  const user = useSelector((state) => state.auth.currentUser)
  return (
    <Container maxWidth="lg" sx={{ pt: 5 }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Welcome, {user.name}</Typography>
        </Grid>
        < AddWorkProvider />
      </Grid>
    </Container>
  )
}
